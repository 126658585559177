import axios from "axios";
import { makeAutoObservable } from "mobx";
import { API_BASE_URL, API_ENDPOINTS } from '../config'
import UpdateService from '../services/updateService';
import soundService from '../services/soundService';
import analyticsService from '../services/analyticsService';

class GameStore {
  INITIAL_SCORE = 1000;  // X - начальные очки
  MOVE_PENALTY = 5;    // Y - штраф за ход
  FOUNDATION_BONUS = 55 ; // N - бонус за карту в домик
  SUIT_COMPLETE_BONUS = 400; // M - бонус за собранную масть

  tickets = 2;
  myScore = 10;
  myPosition = "???";
  token = "";
  myName = "";
  gameTime = 0;
  currentGame = {
    score: this.INITIAL_SCORE,
    moves: 0,
    isStarted: false,
    lastScoreChange: { amount: 0, timestamp: 0 },
    victoryTime: 0,
    hash: ""
  };
  loginStatus = false;
  dataUnsafe = {};
  error = null;

  opponent = null;
  friends = [];
  leaderboard = [];

  // Наблюдаемые свойства
  deck = []; // Оставшиеся карты в колоде
  columns = []; // Карты в колонках
  discardPile = []; // Сброшенные карты
  draggedCards = []; // карты в процессе перетаскивания
  foundations = { hearts: [], diamonds: [], clubs: [], spades: [] }; // "Домики"
  history = [];
  hasFirstMoveOccurred = false;
  noMovesDetected = false;
  isAutoCompleting = false;
  isNewProfile = false;
  alert = {
    show: false,
    content: null
  };
  
  analyticsService = null;

  constructor() {
    makeAutoObservable(this);

    new UpdateService(this);
  }

  get formattedTime() {
    const minutes = Math.floor(this.gameTime / 60);
    const seconds = this.gameTime % 60;
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  }

  // Проверка на победу (все масти собраны)
  get isVictory() {
    return Object.values(this.foundations).every(foundation => foundation.length === 13);
  }

  setAnalyticsService(service) {
    this.analyticsService = service;
  }

  updateGameTime = () => {
    this.gameTime += 1;
  };

  updateToken(token) {
    this.token = token
  }

  startNewGame = () => {
    if (this.tickets > 0) {
      this.tickets--;
      this.resetGame();
      this.currentGame.isStarted = true;
      this.currentGame.score = this.INITIAL_SCORE;
      this.currentGame.moves = 0;
      this.hasFirstMoveOccurred = false;
      this.gameTime = 0;
      soundService.playNewGame();
      // analyticsService.logGameStart();
      if (this.analyticsService) {
        this.analyticsService.logGameStart();
      }
    }
  };

  addTickets(amount) {
    this.tickets += amount;
  }

  updateOpponent(name, tickets) {
    this.opponent = { name, tickets };
  }

  updateStatus(status) {
    this.loginStatus = status
    if (!status) {
      this.setError("Не удалось авторизоваться");
    }
  }

  setError(message) {
    this.error = message;
  }

  clearError() {
    this.error = null;
  }

  updateDataUnsafe(status) {
    this.dataUnsafe = status;
    if (status?.user) {
      this.myName = status.user.first_name || status.user.last_name || status.user.username || status.user.id?.toString() || "Player";
    }
  }

  // Обновление очков с анимацией
  updateScore(amount) {
    const newScore = Math.max(0, this.currentGame.score + amount);
    this.currentGame.lastScoreChange = {
      amount: amount,
      timestamp: Date.now()
    };
    this.currentGame.score = newScore;
  }

  // Метод для генерации колоды
  generateDeck() {
    const suits = ["hearts", "diamonds", "clubs", "spades"];
    const colors = { hearts: "red", diamonds: "red", clubs: "black", spades: "black" };

    return suits.flatMap((suit) =>
      Array.from({ length: 13 }, (_, i) => ({
        id: `${suit}-${i + 1}`,
        value: i + 1,
        suit,
        color: colors[suit],
        isFaceUp: false,
        location: "deck",
      }))
    );
  }

  // Метод для инициализации новой игры
  initializeGame() {
    this.columns = Array.from({ length: 7 }, () => []);
    this.deck = [];

    axios.post(`${API_BASE_URL}${API_ENDPOINTS.DECK}`, {}, {
      headers: {
        'Authorization': `Bearer ${this.token}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then(response => {
        const data = response.data;
        if (response.status === 200) {
          this.clearError();          
          if (!data.playground) {
            this.setError("Некорректные данные от сервера");
            return;
          }

          const playgroundData = typeof data.playground === 'string' ? JSON.parse(data.playground) : data.playground;
          
          const transformCard = (card) => ({
            id: `${card.s === 'h' ? 'hearts' : card.s === 'd' ? 'diamonds' : card.s === 'c' ? 'clubs' : 'spades'}-${card.v}`,
            value: card.v,
            suit: card.s === 'h' ? 'hearts' : card.s === 'd' ? 'diamonds' : card.s === 'c' ? 'clubs' : 'spades',
            color: card.c,
            isFaceUp: card.f || card.isFaceUp || false,
            location: card.location || card.l
          });

          if (playgroundData && playgroundData.tableCols) {
            this.columns = playgroundData.tableCols.map(column => 
              column.map(card => transformCard(card))
            );
          } else {
            this.columns = Array.from({ length: 7 }, () => []);
          }

          if (playgroundData && playgroundData.stock) {
            this.deck = playgroundData.stock.map(card => transformCard(card));
          } else {
            console.error('No stock data in playground');
            this.deck = [];
          }

          if (data.profile) {
            this.updateProfile(data.profile);
          }

          if (data.hash) {
            this.currentGame.hash = data.hash;
          }
        } else {
          this.setError("Не удалось загрузить игру");
          this.fallbackInitializeGame();
        }
      })
      .catch(error => {
        console.error('Failed to fetch deck:', error);
        this.setError("Не удалось загрузить игру");
        this.fallbackInitializeGame();
      });
  }

  // Fallback method for local deck generation
  fallbackInitializeGame() {
    const shuffledDeck = this.generateDeck().sort(() => Math.random() - 0.5);
    this.columns = Array.from({ length: 7 }, () => []);
    let deckIndex = 0;

    for (let col = 0; col < 7; col++) {
      for (let row = 0; row <= col; row++) {
        const card = shuffledDeck[deckIndex];
        card.location = `column-${col}`;
        card.isFaceUp = row === col;
        this.columns[col].push(card);
        deckIndex++;
      }
    }

    this.deck = shuffledDeck.slice(deckIndex).map((card) => ({
      ...card,
      location: "deck",
    }));
  }

  setDraggedCards = (cards) => {
    const isSame =
      this.draggedCards.length === cards.length &&
      this.draggedCards.every((card, index) => card.id === cards[index].id);
    if (!isSame) {
      this.draggedCards = cards;
    }
  };

  // Метод для сброса перетаскиваемых карт
  clearDraggedCards = () => {
    if (this.draggedCards.length > 0) {
      this.draggedCards = [];
    }
  };

  moveCards = async (fromColumnIndex, toColumnIndex, cards) => {
    // Проверяем, что карты не возвращаются в тот же столбец
    if (fromColumnIndex === toColumnIndex) {
      return;
    }

    this.saveState();

    let fromColumn;

    if (fromColumnIndex === -2) { // Discard
      fromColumn = this.discardPile;
    } else if (fromColumnIndex === -3) { // Deck (если используете индекс -3 для Deck)
      fromColumn = this.deck;
    } else { // Колонки 0-6
      fromColumn = this.columns[fromColumnIndex];
    }

    let toColumn;

    if (toColumnIndex >= 0 && toColumnIndex < this.columns.length) { // Целевая колонка 0-6
      toColumn = this.columns[toColumnIndex];
    } else {
      console.error("Одной из колонок не существует.");
      soundService.playWrongMove();
      return;
    }

    const firstCard = cards[0];
    const cardIndex = fromColumn.findIndex((c) => c.id === firstCard.id);
    if (cardIndex === -1) {
      console.error(
        `Карта не найдена в колонке: ${fromColumnIndex}`,
        "card:",
        firstCard,
        "Идентификаторы карт в колонке:",
        fromColumn.map((c) => c.id)
      );
      soundService.playWrongMove();
      return;
    }

    const cardsToMove = fromColumn.slice(cardIndex); // Карты для перемещения
    const targetCard = toColumn[toColumn.length - 1]; // Верхняя карта целевой колонки

    // Проверка правил на первую карту
    if (
      targetCard &&
      (targetCard.color === firstCard.color || targetCard.value !== firstCard.value + 1)
    ) {
      console.warn("Невозможно переместить карты: нарушены правила.");
      soundService.playWrongMove();
      return;
    }

    if (!targetCard && firstCard.value !== 13) {
      console.warn("Можно положить только короля на пустую колонку.");
      soundService.playWrongMove();
      return;
    }

    // Перемещаем карты
    fromColumn.splice(cardIndex, cardsToMove.length);
    const targetLocation = `column-${toColumnIndex}`;
    cardsToMove.forEach((c) => (c.location = targetLocation));
    toColumn.push(...cardsToMove);

    // Открываем верхнюю карту в исходной колонке
    if (fromColumn.length > 0) {
      fromColumn[fromColumn.length - 1].isFaceUp = true;
    }

    // Форматируем и отправляем ход
    const moveString = this.formatMoveString(firstCard, targetLocation);
    this.sendMove(moveString);

    // Обновляем счет
    this.updateScore(-this.MOVE_PENALTY);
    this.hasFirstMoveOccurred = true;
    this.checkForNoMoves();
    soundService.playCardMove();
  };

  // Update profile data from API response
  updateProfile = (profile) => {
    if (profile) {
      this.tickets = profile.tickets || 0;
      this.myScore = profile.score || 0;
      this.myName = profile.name || this.myName;
    }
  }; 

  async fetchProfile() {
    try {
      const response = await axios.get(`${API_BASE_URL}${API_ENDPOINTS.PROFILE}`, {
        headers: {
          'Authorization': `Bearer ${this.token}`,
          'Content-Type': 'application/json'
        }
      });
      if (response.status === 200) {
        this.updateProfile(response.data.profile);
      }
    } catch (error) {
      console.error('Failed to fetch profile:', error);
      this.setError("Не удалось загрузить профиль");
    }
  };

  async fetchFriends() {
    try {
      const response = await axios.get(`${API_BASE_URL}${API_ENDPOINTS.FRIENDS}`, {
        headers: {
          'Authorization': `Bearer ${this.token}`,
          'Content-Type': 'application/json'
        }
      });
      if (response.status === 200) {
        this.friends = Array.isArray(response.data) ? response.data.map(friend => ({
          ...friend,
          name: decodeURIComponent(friend.name)
        })) : [];
      }
    } catch (error) {
      console.error('Failed to fetch friends:', error);
      this.setError("Не удалось загрузить список друзей");
    }
  }

  async fetchLeaderboard() {
    try {
      const response = await axios.get(`${API_BASE_URL}${API_ENDPOINTS.LEADERBOARD}`, {
        headers: {
          'Authorization': `Bearer ${this.token}`,
          'Content-Type': 'application/json'
        }
      });
      if (response.status === 200) {
        this.leaderboard = Array.isArray(response.data.leaderboard) ? 
          response.data.leaderboard.map(player => ({
            ...player,
            name: decodeURIComponent(player.name)
          })) : [];
        this.myPosition = response.data.position || "???";
      }
    } catch (error) {
      console.error('Failed to fetch leaderboard:', error);
      this.setError("Не удалось загрузить таблицу лидеров");
    }
  }

  // Сохраняет текущее состояние игры в историю
  saveState = () => {
    const stateSnapshot = {
      deck: this.deck.map(card => ({ ...card })),
      columns: this.columns.map(column => column.map(card => ({ ...card }))),
      discardPile: this.discardPile.map(card => ({ ...card })),
      foundations: {
        hearts: this.foundations.hearts.map(card => ({ ...card })),
        diamonds: this.foundations.diamonds.map(card => ({ ...card })),
        clubs: this.foundations.clubs.map(card => ({ ...card })),
        spades: this.foundations.spades.map(card => ({ ...card })),
      },
      score: this.currentGame.score,
      lastScoreChange: { ...this.currentGame.lastScoreChange }
    };
    // Сохраняем состояние только если произошло реальное изменение
    const lastState = this.history[this.history.length - 1];
    if (!lastState || JSON.stringify(lastState) !== JSON.stringify(stateSnapshot)) {
      this.history.push(stateSnapshot);
      // Обновляем количество ходов на основе истории
      this.currentGame.moves = this.history.length;
    }
  };

  // Отменяет последний ход, восстанавливая предыдущее состояние игры
  undo = () => {
    if (this.history.length > 0) {
      const previousState = this.history.pop();
      const scoreDiff = previousState.score - this.currentGame.score;

      this.deck = previousState.deck;
      this.columns = previousState.columns;
      this.discardPile = previousState.discardPile;
      this.foundations = previousState.foundations;
      this.currentGame.score = previousState.score;

      // Обновляем lastScoreChange с положительным значением
      this.currentGame.lastScoreChange = {
        amount: Math.abs(scoreDiff), // Всегда показываем положительное значение при отмене
        timestamp: Date.now()
      };

      // Обновляем количество ходов на основе истории
      this.currentGame.moves = this.history.length;
    }
    if (this.hasFirstMoveOccurred) {
      this.checkForNoMoves();
    }
  };

  moveCardsToFoundation = (cards, suit) => {
    this.saveState();

    if (cards.length !== 1) {
      console.warn("Можно перемещать только одну карту в foundations.");
      return;
    }

    const card = cards[0];

    if (card.suit !== suit) {
      console.warn("Неверная масть для данной foundation.");
      return;
    }

    const foundation = this.foundations[suit];
    const lastCard = foundation[foundation.length - 1];

    if (foundation.length === 0 && card.value === 1) {
      // Перемещаем туз в пустую foundation
      this.removeCardsFromSource(card);
      const targetLocation = `foundation-${suit}`;
      foundation.push(card);
      card.location = targetLocation;

      // Форматируем и отправляем ход
      const moveString = this.formatMoveString(card, targetLocation);
      this.sendMove(moveString);

      const totalScoreChange = this.FOUNDATION_BONUS - this.MOVE_PENALTY;
      this.updateScore(totalScoreChange);
      this.checkSuitCompletion(suit);
    } else if (lastCard && card.value === lastCard.value + 1) {
      // Перемещаем карту на foundation
      this.removeCardsFromSource(card);
      const targetLocation = `foundation-${suit}`;
      foundation.push(card);
      card.location = targetLocation;

      // Форматируем и отправляем ход
      const moveString = this.formatMoveString(card, targetLocation);
      this.sendMove(moveString);

      const totalScoreChange = this.FOUNDATION_BONUS - this.MOVE_PENALTY;
      this.updateScore(totalScoreChange);
      this.checkSuitCompletion(suit);
    } else {
      console.warn("Невозможно переместить карту в foundation: нарушены правила.");
    }
    this.hasFirstMoveOccurred = true;
    this.checkForNoMoves();
    soundService.playCardMove();
  };

  removeCardsFromSource = (card) => {
    const { location } = card;
    if (location.startsWith("column-")) {
      const columnIndex = parseInt(location.split("-")[1], 10);
      const column = this.columns[columnIndex];
      const cardIndex = column.findIndex((c) => c.id === card.id);
      if (cardIndex !== -1) {
        column.splice(cardIndex, 1);
        // Открываем верхнюю карту, если необходимо
        if (column.length > 0) {
          column[column.length - 1].isFaceUp = true;
        }
      }
    } else if (location === "deck") {
      const deckIndex = this.deck.findIndex((c) => c.id === card.id);
      if (deckIndex !== -1) {
        this.deck.splice(deckIndex, 1);
      }
    } else if (location.startsWith("foundation-")) {
      const foundationSuit = location.split("-")[1];
      const foundation = this.foundations[foundationSuit];
      const cardIndex = foundation.findIndex((c) => c.id === card.id);
      if (cardIndex !== -1) {
        foundation.splice(cardIndex, 1);
      }
    } else if (location === "discardPile") {
      const cardIndex = this.discardPile.findIndex((c) => c.id === card.id);
      if (cardIndex !== -1) {
        this.discardPile.splice(cardIndex, 1);
      }
    }
  };

  // Проверка на завершение масти
  checkSuitCompletion(suit) {
    const foundation = this.foundations[suit];
    if (foundation.length === 13) { // Все карты масти собраны
      this.updateScore(this.SUIT_COMPLETE_BONUS);
    }
  }

  // Метод для вытаскивания карты из колоды
  drawCard = () => {
    if (this.deck.length === 0) {
      // Если колода пуста, переворачиваем сброс
      if (this.discardPile.length === 0) return;

      this.saveState(); // Сохраняем состояние только один раз при перемешивании
      this.deck = this.discardPile
        .reverse()
        .map(card => ({
          ...card,
          isFaceUp: false,
          location: "deck",
        }))
        .sort(() => Math.random() - 0.5);
      this.discardPile = [];
      soundService.playCardFlip();
      return;
    }

    this.saveState();

    // Берем карту из колоды
    const card = this.deck.pop();
    card.isFaceUp = true;
    card.location = "discardPile"; // Устанавливаем местоположение
    this.discardPile.push(card); // Добавляем в сброшенные карты

    // Обновляем очки за ход
    this.updateScore(-this.MOVE_PENALTY);
    this.hasFirstMoveOccurred = true;
    this.checkForNoMoves();
    soundService.playCardFlip();
  };

  // Метод для сброса карты
  discardCard = (card) => {
    // Проверяем, что карта не возвращается в тот же сброс
    if (card.location === "discardPile" || card.location.startsWith("column-")) {
      return;
    }

    this.saveState();
    card.location = "discardPile";
    this.discardPile.push(card);
    this.removeCardsFromSource(card);
  };

  // Reset game and clear saved state
  resetGame = () => {
    this.clearSavedGame();
    this.deck = [];
    this.discardPile = [];
    this.columns = [];
    this.foundations = { hearts: [], diamonds: [], clubs: [], spades: [] };
    this.currentGame = {
      score: this.INITIAL_SCORE,
      moves: 0,
      isStarted: false,
      lastScoreChange: { amount: 0, timestamp: 0 },
      victoryTime: 0,
      hash: ""
    };
    this.gameTime = 0;
    this.history = [];
    this.hasFirstMoveOccurred = false;
    this.initializeGame();
  };

  // Send win data to server
  sendWinData = (scores) => {
    return axios.post(`${API_BASE_URL}${API_ENDPOINTS.WIN}`, {
      hash: this.currentGame.hash,
      scores,
    }, {
      headers: {
        'Authorization': `Bearer ${this.token}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });
  }

  saveGameToStorage = () => {
    const gameState = {
      deck: this.deck,
      columns: this.columns,
      discardPile: this.discardPile,
      foundations: this.foundations,
      currentGame: this.currentGame,
      gameTime: this.gameTime,
      history: this.history,
      token: this.token,
      tickets: this.tickets,
      myScore: this.myScore,
      myPosition: this.myPosition,
      opponent: this.opponent,
      friends: this.friends,
      leaderboard: this.leaderboard,
    };
    localStorage.setItem('solitaireGameState', JSON.stringify(gameState));
  };

  // Load game state from localStorage
  loadGameFromStorage = () => {
    const savedState = localStorage.getItem('solitaireGameState');
    if (savedState) {
      try {
        const gameState = JSON.parse(savedState);
        this.deck = gameState.deck;
        this.columns = gameState.columns;
        this.discardPile = gameState.discardPile;
        this.foundations = gameState.foundations;
        this.currentGame = gameState.currentGame;
        this.gameTime = gameState.gameTime;
        this.history = gameState.history;
        this.tickets = gameState.tickets;
        this.myScore = gameState.myScore;
        this.myPosition = gameState.myPosition;
        this.opponent = gameState.opponent;
        this.friends = gameState.friends;
        this.leaderboard = gameState.leaderboard;
        return true;
      } catch (error) {
        console.error('Failed to load game state:', error);
        localStorage.removeItem('solitaireGameState');
        return false;
      }
    }
    return false;
  };

  // Clear saved game state
  clearSavedGame = () => {
    localStorage.removeItem('solitaireGameState');
  };

  // Проверяет, можно ли переместить карту в foundation
  canMoveToFoundation = (card) => {
    if (!card || !card.isFaceUp) return false;

    // Проверяем, является ли карта последней в колонке или в сбросе
    const isLastInColumn = this.columns.some(column => 
      column.length > 0 && column[column.length - 1].id === card.id
    );
    const isLastInDiscard = this.discardPile.length > 0 && 
      this.discardPile[this.discardPile.length - 1].id === card.id;

    if (!isLastInColumn && !isLastInDiscard) return false;

    const foundation = this.foundations[card.suit];
    const lastCard = foundation[foundation.length - 1];

    return (foundation.length === 0 && card.value === 1) ||
      (lastCard && card.value === lastCard.value + 1);
  }

  isDoubleClickInProgress = false;
  setCardShaking = (cardId, isShaking) => {
    // Находим карту во всех возможных местах
    const allCards = [
      ...this.deck,
      ...this.discardPile,
      ...this.columns.flat(),
      ...Object.values(this.foundations).flat()
    ];
    
    const card = allCards.find(c => c.id === cardId);
    if (card) {
      card.isShaking = isShaking;
    }
  }

  handleDoubleClick = (card) => {
    if (this.isDoubleClickInProgress) return;
    if (!this.canMoveToFoundation(card)) {
      // Если карту нельзя переместить, включаем анимацию тряски
      this.setCardShaking(card.id, true);
      return;
    }

    this.isDoubleClickInProgress = true;

    this.moveCardsToFoundation([card], card.suit);

    this.hasFirstMoveOccurred = true;

    setTimeout(() => {
      this.isDoubleClickInProgress = false;
    }, 300);
  };

  // Получение ссылки для приглашения друзей
  getInviteLink = () => {
    return new Promise((resolve, reject) => {
      axios.get(`${API_BASE_URL}${API_ENDPOINTS.FRIEND_INVITE}`, {
        headers: {
          'Authorization': `Bearer ${this.token}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
        .then(response => {
          this.clearError();

          // Проверяем наличие данных
          if (!response.data) {
            reject(new Error('Нет данных в ответе'));
            return;
          }

          // Если ссылка сразу доступна
          if (response.data.link) {
            resolve(response.data.link);
            return;
          }

          // Если получили id, возвращаем его как ссылку
          if (response.data.id) {
            resolve(response.data.id);
            return;
          }

          reject(new Error('Не удалось получить ссылку'));
        })
        .catch(error => {
          this.setError("Что то пошло не так...");
          console.error('Error getting invite link:', error);
          reject(error);
        });
    });
  }

  getInviteLobbyLink = () => {
    return new Promise((resolve, reject) => {
      axios.get(`${API_BASE_URL}${API_ENDPOINTS.GAME_INVITE}`, {
        headers: {
          'Authorization': `Bearer ${this.token}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
        .then(response => {
          this.clearError();

          // Проверяем наличие данных
          if (!response.data) {
            reject(new Error('Нет данных в ответе'));
            return;
          }

          // Если ссылка сразу доступна
          if (response.data.link) {
            resolve(response.data.link);
            return;
          }

          // Если получили id, возвращаем его как ссылку
          if (response.data.id) {
            resolve(response.data.id);
            return;
          }

          reject(new Error('Не удалось получить ссылку'));
        })
        .catch(error => {
          this.setError("Что то пошло не так...");
          console.error('Error getting invite link:', error);
          reject(error);
        });
    });
  }

  getInviteLobby = () => {
    return new Promise((resolve, reject) => {
      axios.get(`${API_BASE_URL}${API_ENDPOINTS.GAME_INVITE}`, {
        headers: {
          'Authorization': `Bearer ${this.token}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
        .then(response => {
          this.clearError();

          // Проверяем наличие данных
          if (!response.data) {
            reject(new Error('Нет данных в ответе'));
            return;
          }

          // Если ссылка сразу доступна
          if (response.data.link) {
            resolve(response.data.link);
            return;
          }

          // Если получили id, возвращаем его как ссылку
          if (response.data.id) {
            resolve(response.data.id);
            return;
          }

          reject(new Error('Не удалось получить ссылку'));
        })
        .catch(error => {
          this.setError("Что то пошло не так...");
          console.error('Error getting invite link:', error);
          reject(error);
        });
    });
  }

  inviteLink = "";
  showInviteModal = false;

  inviteFriends = async (isLobby) => {
    try {
      const link = isLobby ? await this.getInviteLobby() : await this.getInviteLink();
      if (!link) {
        throw new Error("Ссылка отсутствует");
      }

      // Функция для копирования в буфер обмена
      const copyToClipboard = async (text) => {
        try {
          if (navigator.clipboard && typeof navigator.clipboard.writeText === "function") {
            await navigator.clipboard.writeText(text);
            return true;
          } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
            // Фолбэк для старых браузеров
            const textarea = document.createElement("textarea");
            textarea.textContent = text;
            textarea.style.position = "fixed"; // Избегаем прокрутки страницы
            document.body.appendChild(textarea);
            textarea.select();
            try {
              document.execCommand("copy");
              return true;
            } catch (ex) {
              console.warn("Не удалось скопировать в буфер:", ex);
              return false;
            } finally {
              document.body.removeChild(textarea);
            }
          } else {
            return false;
          }
        } catch (err) {
          console.warn("Ошибка при копировании в буфер:", err);
          return false;
        }
      };

      // Попытка скопировать ссылку в буфер
      const copied = await copyToClipboard(link);
      if (copied) {
        // Уведомление пользователя об успешном копировании
        if (window.Telegram?.WebApp) {
          window.Telegram.WebApp.showPopup({
            title: "Успешно",
            message: "Ссылка для приглашения скопирована в буфер обмена",
            buttons: [{ type: "ok", text: "OK" }],
          });
        } else {
          alert("Ссылка для приглашения скопирована в буфер обмена!");
        }
        return; // Завершаем выполнение после успешного копирования
      }

      // Если копирование не удалось, пробуем открыть Telegram WebApp Inline Query
      if (window.Telegram?.WebApp) {
        try {
          await window.Telegram.WebApp.switchInlineQuery(link);
          return; // Успешное выполнение Inline Query
        } catch (inlineError) {
          console.warn("Ошибка switchInlineQuery:", inlineError);
        }
      }

      // Если Inline Query не сработало, пробуем использовать нативное "Поделиться"
      if (navigator.share) {
        try {
          await navigator.share({
            title: "Приглашение в игру",
            text: `Присоединяйтесь к игре: ${link}`,
            url: link,
          });
          return; // Успешное выполнение share
        } catch (shareError) {
          console.warn("Ошибка при использовании 'Поделиться':", shareError);
        }
      }

      // Если все остальные способы не сработали, показываем модальное окно с ссылкой
      this.inviteLink = link;
      this.showInviteModal = true;

    } catch (error) {
      console.error("Ошибка при попытке пригласить друзей:", error);
      if (window.Telegram?.WebApp) {
        window.Telegram.WebApp.showPopup({
          title: "Ошибка",
          message: error.message || "Не удалось выполнить действие",
          buttons: [{ type: "ok", text: "OK" }],
        });
      } else {
        alert("Ошибка: " + (error.message || "Не удалось выполнить действие"));
      }
    }
  };

  // Обработка хэша приглашения
  handleInviteHash = async (hash) => {
    try {
      const response = await axios.post(`${API_BASE_URL}${API_ENDPOINTS.FRIEND_ACCESS}`,
        { hash },
        {
          headers: {
            'Authorization': `Bearer ${this.token}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        }
      );

      if (response.data?.profile) {
        this.clearError();
        this.updateProfile(response.data.profile);
        await this.fetchProfile();
      }

      return response.data;
    } catch (error) {
      this.setError("Что то пошло не так...");
      console.error('Error handling invite:', error);
      throw error;
    }
  }

  handleLobbyHash = async (hash) => {
    try {
      const response = await axios.post(`${API_BASE_URL}${API_ENDPOINTS.GAME_ACCESS}`,
        { hash },
        {
          headers: {
            'Authorization': `Bearer ${this.token}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        }
      );

      if (response.data?.profile) {
        this.updateProfile(response.data.profile);
        await this.fetchProfile();
        // update cards
      }

      if (response.data?.lobby) {
        this.updateLobby(response.data.lobby);
      }
      this.clearError();
      return response.data;
    } catch (error) {
      this.setError("Что то пошло не так...");
      console.error('Error handling lobby:', error);
      throw error;
    }
  }

  updateLobby = (lobbyData) => {
    if (lobbyData.type) {
      this.lobby.type = lobbyData.type;
    }
    if (lobbyData.opponent) {
      this.lobby.opponent = lobbyData.opponent;
    }
    if (lobbyData.status) {
      this.lobby.status = lobbyData.status;
    }
  }

  // Проверка URL на наличие invite параметра
  checkInviteUrl = () => {
    try {
      // Получаем URL из Telegram WebApp
      let params = window.Telegram?.WebApp?.initDataUnsafe?.start_param;
      params = atob(params)
      params = JSON.parse(params)
      console.log(params)
      if (params.type == "friend") {
        this.handleInviteHash(params.hash);
      } if (params.type == "duel") {
        this.handleLobbyHash(params.hash);
      }
    } catch (error) {
      console.error('Error checking invite URL:', error);
    }
  }

  // Create invoice for payment
  createInvoice = async (itemId, price, quantity) => {
    try {
      const response = await axios.get(`${API_BASE_URL}${API_ENDPOINTS.PAYMENT_CREATE_INVOICE}?quantity=${quantity}`, {
        headers: {
          'Authorization': `Bearer ${this.token}`,
          'Content-Type': 'application/json',
        },
      });
      this.clearError();
      return response.data;
    } catch (error) {
      this.setError("Что то пошло не так...");
      console.error('Failed to create invoice:', error);
      throw error;
    }
  };

  ANIMATION_DELAY = 200;

  delay = (ms) => new Promise(resolve => {
    const start = performance.now();
    const frame = () => {
      const current = performance.now();
      if (current - start >= ms) {
        resolve();
      } else {
        requestAnimationFrame(frame);
      }
    };
    requestAnimationFrame(frame);
  });

  autoComplete = async () => {
    this.isAutoCompleting = true;

    try {
      while (true) {
        let foundMove = false;

        if (this.discardPile.length > 0) {
          const card = this.discardPile[this.discardPile.length - 1];
          if (this.canMoveToFoundation(card)) {
            await this.delay(this.ANIMATION_DELAY);
            this.moveToFoundation(card);
            foundMove = true;
            continue;
          }
        }

        if (!foundMove && this.deck.length > 0) {
          await this.delay(this.ANIMATION_DELAY);
          this.drawCard();
          
          if (this.discardPile.length > 0) {
            const card = this.discardPile[this.discardPile.length - 1];
            if (this.canMoveToFoundation(card)) {
              await this.delay(this.ANIMATION_DELAY);
              this.moveToFoundation(card);
              foundMove = true;
              continue;
            }
          }
        }

        if (!foundMove) {
          for (let i = 0; i < this.columns.length; i++) {
            const column = this.columns[i];
            if (column.length > 0) {
              const card = column[column.length - 1];
              if (this.canMoveToFoundation(card)) {
                await this.delay(this.ANIMATION_DELAY);
                this.moveToFoundation(card);
                foundMove = true;
                break;
              }
            }
          }
        }

        if (!foundMove || this.checkGameCompletion()) {
          break;
        }
      }
    } finally {
      this.isAutoCompleting = false;
    }
  };

  checkNoMovesAvailable = () => {

    const hasHiddenCards = this.columns.some(column =>
      column.some(card => !card.isFaceUp)
    );

    if (!hasHiddenCards) {
      // this.autoComplete(); //пока убираем, есть баги
      return false;
    }

    // Проверка возможности перемещения карт в домик
    const canMoveToFoundation = this.columns.some(column =>
      column.some(card => this.canMoveToFoundation(card))
    ) || this.discardPile.some(card => this.canMoveToFoundation(card))
      || this.deck.some(card => this.canMoveToFoundation(card));


    // Проверка возможности перемещения карт из сброса и колоды
    const canMoveFromDiscardOrDeck = [...this.discardPile, ...this.deck].some(card =>
      this.columns.some(column =>
        (column.length === 0 && card.value === 13) || 
        (column.length > 0 &&
          card.color !== column[column.length - 1].color &&
          card.value === column[column.length - 1].value - 1)
      )
    );


    // Проверка возможности перемещения между столбцами с учетом прогресса
    const canMoveBetweenColumns = this.columns.some((sourceColumn, sourceIndex) =>
      sourceColumn.some((card, cardIndex) => {
        if (!card.isFaceUp) return false;

        return this.columns.some((targetColumn, targetIndex) => {
          if (sourceIndex === targetIndex) return false;

          const targetCard = targetColumn[targetColumn.length - 1];
          
          const isValidMove = targetCard && 
                            targetCard.color !== card.color && 
                            card.value === targetCard.value - 1;

          const isEmptyColumnKingMove = targetColumn.length === 0 && card.value === 13;
          const willOpenNewCard = cardIndex > 0 && !sourceColumn[cardIndex - 1].isFaceUp;
          
          const willEnableFoundationMove = targetCard && 
            sourceColumn.some((c, i) => {
              if (i < cardIndex) return false;
              return this.canMoveToFoundation(c);
            });

          const wouldLeadToProgress = 
            (isEmptyColumnKingMove && (willOpenNewCard || this.hasCardsToMoveToEmptyColumn())) || 
            (willOpenNewCard && isValidMove) ||
            willEnableFoundationMove;

          if (wouldLeadToProgress) {
            // console.log('Найдено возможное перемещение:', {
            //   from: `${card.value}${card.suit} из колонки ${sourceIndex}`,
            //   to: targetCard ? `${targetCard.value}${targetCard.suit} в колонке ${targetIndex}` : `пустую колонку ${targetIndex}`,
            //   reason: isEmptyColumnKingMove && willOpenNewCard ? 'король на пустую колонку (откроет новую карту)' :
            //          isEmptyColumnKingMove ? 'король на пустую колонку (есть карты для перемещения)' :
            //          willOpenNewCard ? 'откроет новую карту' :
            //          'создаст возможность для перемещения в домик'
            // });
          }

          return wouldLeadToProgress;
        });
      })
    );


    const noMoves = !canMoveToFoundation && !canMoveFromDiscardOrDeck && !canMoveBetweenColumns;
    
    return noMoves;
  }

  checkForNoMoves = () => {
    if (this.hasFirstMoveOccurred && this.checkNoMovesAvailable()) {
      this.noMovesDetected = true;
    }
  }

  hasCardsToMoveToEmptyColumn = () => {
    // Проверяем королей в столбцах
    const hasKingInColumns = this.columns.some(column => 
      column.length > 1 && // Колонка должна содержать более одной карты
      column.some((card, index) => 
        card.value === 13 && // Ищем короля
        index > 0 && // Король не должен быть первой картой
        !column[index - 1].isFaceUp // Перед королем должна быть закрытая карта
      )
    );

    const hasKingInDeck = this.deck.some(card => card.value === 13);

    const hasKingInDiscard = this.discardPile.some(card => card.value === 13);

    return hasKingInColumns || hasKingInDeck || hasKingInDiscard;
  };

  moveToFoundation = (card) => {
    let sourceArray;
    let sourceIndex;

    sourceIndex = this.discardPile.findIndex(c => c === card);
    if (sourceIndex !== -1) {
      sourceArray = this.discardPile;
    }

    if (!sourceArray) {
      sourceIndex = this.deck.findIndex(c => c === card);
      if (sourceIndex !== -1) {
        this.updateScore(-this.MOVE_PENALTY);
        this.drawCard();
        sourceArray = this.discardPile;
        sourceIndex = this.discardPile.length - 1;
      }
    }

    if (!sourceArray) {
      for (let i = 0; i < this.columns.length; i++) {
        sourceIndex = this.columns[i].findIndex(c => c === card);
        if (sourceIndex !== -1) {
          sourceArray = this.columns[i];
          break;
        }
      }
    }

    if (sourceArray && sourceIndex !== -1) {
      this.updateScore(-this.MOVE_PENALTY);
      
      sourceArray.splice(sourceIndex, 1);
      
      this.foundations[card.suit].push(card);
      
      this.updateScore(this.FOUNDATION_BONUS);
      soundService.playCardMove(); // Добавляем звук при успешном перемещении в домик

      if (this.foundations[card.suit].length === 13) {
        this.updateScore(this.SUIT_COMPLETE_BONUS);
      }
    }
  };

  checkGameCompletion = () => {
    const allFoundationsComplete = Object.values(this.foundations).every(
      foundation => foundation.length === 13
    );

    if (allFoundationsComplete) {
      soundService.playVictory();
      return true;
    }
    return false;
  };

  formatMoveString = (card, targetLocation) => {
    // Получаем короткое обозначение масти (h, d, c, s)
    const suitMap = {
      'hearts': 'h',
      'diamonds': 'd',
      'clubs': 'c', 
      'spades': 's'
    };
    
    // Получаем значение и масть карты
    const cardValue = card.value;
    const cardSuit = suitMap[card.suit];
    
    // Форматируем целевую локацию
    let target;
    if (targetLocation.startsWith('column-')) {
      target = `c-${parseInt(targetLocation.split('-')[1]) + 1}`;
    } else if (targetLocation.startsWith('foundation-')) {
      target = `f-${suitMap[targetLocation.split('-')[1]]}`;
    } else if (targetLocation === 'discardPile') {
      target = 'dp';
    }
    
    // Возвращаем отформатированную строку хода
    return `${cardSuit}-${cardValue} in ${target}`;
  };

  sendMove = async (moveString) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}${API_ENDPOINTS.MOVE}`,
        { move: moveString },
        {
          headers: {
            'Authorization': `Bearer ${this.token}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        }
      );
      
      if (response.status === 200) {
        return true;
      }
    } catch (error) {
      // console.error('Failed to send move:', error);
      // this.setError("Не удалось отправить ход");
      return true;
    }
  };

  async handleNewProfile() {
    this.isNewProfile = true;
  }

  setProfileTutorialShown() {
    this.isNewProfile = false;
  }

  updateTickets(total, income) {
    this.tickets = total;
  }

  showAlert(content) {
    this.alert = {
      show: true,
      content
    };
  }

  hideAlert() {
    this.alert.show = false;
  }
}

const gameStore = new GameStore();
export default gameStore;
