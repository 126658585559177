import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import gameStore from '../store/store';

const Alert = observer(() => {
  useEffect(() => {
    if (gameStore.alert.show) {
      const timer = setTimeout(() => {
        gameStore.hideAlert();
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [gameStore.alert.show]);

  if (!gameStore.alert.show) {
    return null;
  }

  return (
    <div className="fixed top-2 left-2 right-2 transform z-50">
      <div className="bg-[#623FA5] text-[#F6F3FC] px-2 py-1 rounded-lg flex items-center gap-2"
           style={{
             border: "1px solid #623FA5",
           }}>
        {gameStore.alert.content}
      </div>
    </div>
  );
});

export default Alert;
