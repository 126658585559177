import React, { useState } from "react";
import BottomNavigation from "../components/BottomNavigation";
import TicketCounter from "../components/TicketCounter";
import bannerImg from "../assets/img/shopTop.png";
import shopItemImg from "../assets/img/shopItem.png";
import Button from "../components/Button";
import TicketReward from "../components/TicketReward";
import Modal from "../components/Modal"; // Импортируем модальное окно
import icons from "../assets/icons";
import gameStore from "../store/store";

const ShopPage = () => {
  const items = [
    { id: 1, quantity: 1, price: "1" },
    { id: 2, quantity: 2, price: "2" },
    { id: 3, quantity: 3, price: "3" },
    { id: 4, quantity: 4, price: "4" },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handlePurchase = async () => {
    if (!selectedItem) return;

    setIsLoading(true);
    try {
      const response = await gameStore.createInvoice(
        selectedItem.id,
        selectedItem.price,
        selectedItem.quantity
      );

      const { link } = response;
      window.Telegram.WebApp.openTelegramLink(link);

      setIsModalOpen(false);

    } catch (error) {
      console.error('Purchase failed:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectItem = (item) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  return (
    <div
      className="h-screen flex flex-col items-center justify-start overflow-y-auto"
      style={{ paddingTop: "72px", paddingBottom: "85px" }}
    >
      {/* Заголовок */}
      <TicketCounter />

      <div
        className="relative w-11/12 max-w-md rounded-xl"
        style={{
          border: "2px solid #623FA5",
        }}
      >
        <img
          src={bannerImg}
          alt="Приглашайте друзей"
          className="w-full h-full object-cover"
        />
        <div className="absolute right-2 bottom-9 flex-col">
          <TicketReward
            className="mb-2"
            iconClassName="h-8 w-8"
            textClassName="text-3xl"
            text="1"
            icon={icons.Ticket}
          />
          <Button
            text="Получить"
            icon={icons.Ad}
            className="bg-[#C9F456] hover:bg-gray-100 h-[45px] px-3"
            textClassName="text-[#247A0A]"
            iconClassName="flex ml-0"
            style={{
              boxShadow: "0px 4px 0px 0px #247A0A",
            }}
          />
        </div>
      </div>

      {/* Список товаров */}
      <div className="mt-2 w-11/12 max-w-md">
        <div className="grid grid-cols-2 gap-1">
          {items.map((item) => (
            <div
              key={item.id}
              className="relative text-center rounded-lg min-h-[200px]"
              style={{
                border: "2px solid #623FA5",
                background:
                  "linear-gradient(0deg, rgba(98, 63, 165, 0.66), rgba(98, 63, 165, 0.66)), radial-gradient(66.14% 66.14% at 50.09% 12.6%, rgba(247, 163, 255, 0.2) 0%, rgba(247, 163, 255, 0) 100%)",
                backgroundBlendMode: "overlay",
              }}
            >
              <img
                src={shopItemImg}
                alt="Приглашайте друзей"
                className="w-10/12 absolute -top-6 left-1/2 transform -translate-x-1/2"
              />

              <div className="absolute bottom-2 flex-col w-full px-2">
                <TicketReward
                  className="mb-2"
                  iconClassName="h-8 w-8"
                  textClassName="text-3xl"
                  text={`${item.quantity}`}
                  icon={icons.Ticket}
                />
                <Button
                  text={`${item.price}`}
                  icon={icons.Stars}
                  className="bg-[#C9F456] hover:bg-gray-100 h-[45px] w-full"
                  textClassName="text-[#247A0A]"
                  onClick={() => handleSelectItem(item)}
                  style={{
                    boxShadow: "0px 4px 0px 0px #247A0A",
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Модальное окно */}
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="ПОКУПКА"
        maxHeight={false}
      >
        <div className="px-4 pb-6">
          <p
            className="text-white text-xl font-bold"
            style={{ textShadow: "0px 4px 0px #623FA5" }}
          >ПОПОЛНЕНИЕ БИЛЕТОВ</p>
          {selectedItem && (
            <div
              className="mt-2 rounded-md"
              style={{
                background: "#6641A9A8",
                border: "2px solid #623FA5"
              }}
            >
              <span
                className="text-white text-xl font-bold mt-2"
                style={{ textShadow: "0px 4px 0px #623FA5" }}
              >КОЛИЧЕСТВО:</span>
              <TicketReward
                className="mb-2"
                iconClassName="h-8 w-8"
                textClassName="text-3xl"
                text={`${selectedItem?.quantity}`}
                icon={icons.Ticket}
              />
            </div>
          )}
          {isLoading ? (
            <div className="flex flex-col items-center justify-center mt-4 h-[50px]">
              <div className="w-8 h-8 border-4 border-width-2 border-[#623FA5] border-t-transparent rounded-full animate-spin"></div>
            </div>
          ) : (
            <Button
              text={`${selectedItem?.price}`}
              icon={icons.Stars}
              className="bg-[#C9F456] mt-4 h-[50px] w-full"
              textClassName="text-[#247A0A]"
              style={{
                boxShadow: "0px 4px 0px 0px #247A0A",
              }}
              onClick={handlePurchase}
            />
          )}
        </div>
      </Modal>

      {/* Навигация */}
      <BottomNavigation />
    </div>
  );
};

export default ShopPage;
