import React from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import gameStore from "../store/store";
import Button from "../components/Button";
import ButtonLink from "../components/ButtonLink";
import icons from "../assets/icons";
import logo from "../assets/img/logo.png";
import vs from "../assets/img/vs.png";
import avatarImg from "../assets/img/avatar.png";
import opponentImg from "../assets/img/opponent.png";
import nullOpponentImg from "../assets/img/nullOpponentImg.png";
import Icon from "./Icon";
import TicketReward from "./TicketReward";
import InviteLinkModal from "./InviteLinkModal";

const GameActions = observer(() => {
  const { tickets, opponent, myScore, dataUnsafe, showInviteModal, inviteLink } = gameStore;

  const handleCloseInviteModal = () => {
    gameStore.showInviteModal = false;
  };

  return (
    <div
      className="w-full flex flex-col items-center pb-24"
      style={{
        background: "linear-gradient(180deg, #9141FD 45%, #526FF9 100%)",
        border: "3px solid #623FA5",
        borderTopLeftRadius: "45px",
        borderTopRightRadius: "45px",
      }}
    >
      <div
        className="w-full h-full pt-20 relative"
        style={{
          background: "linear-gradient(90deg, rgba(102, 65, 169, 0) 0%, rgba(102, 65, 169, 0.49) 30%, rgba(102, 65, 169, 0.49) 70%, rgba(102, 65, 169, 0) 100%)"
        }}
      >
        <img
          src={logo}
          alt="Logo"
          className="absolute -top-16 left-1/2 transform -translate-x-1/2 w-36 h-32"
        />

        <div className="flex items-center gap-2 justify-center">
          <TicketReward text="+5" icon={icons.Ticket} />
          <span className="text-[#F6F3FC] font-bold text-lg" style={{ textShadow: "0px 4px 0px #623FA5" }}>
            КАЖДЫЙ ДЕНЬ!
          </span>
        </div>

        <div
          className="w-full mt-2"
          style={{
            height: "1.5px",
            background: "linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.49) 30%, rgba(255, 255, 255, 0.49) 70%, rgba(255, 255, 255, 0) 100%)",
          }}
        ></div>
      </div>

      <div
        className="relative"
        style={{
          position: "relative",
          borderRadius: "30px",
        }}
      >
        <div
          className="absolute inset-0"
          style={{
            background: "radial-gradient(50% 50% at 50% 50%, #F7A3FF 0%, rgba(247, 163, 255, 0) 100%)",
            opacity: 0.4,
            borderRadius: "30px",
            zIndex: 0,
          }}
        ></div>
        <div
          className="relative z-10"
          style={{
            borderRadius: "30px",
          }}
        >
          <h2 className="text-2xl text-center font-bold mt-2 uppercase text-[#F6F3FC]" style={{ textShadow: "0px 4px 0px #623FA5" }}>Играйте с друзьями</h2>
          <p className="text-ls font-semibold" style={{ color: "#F6F3FC99" }}>
            Пригласите друга и получите бонусы
          </p>

          <div className="flex flex-col items-center gap-1 mt-2">
            {/* Верхний ряд: аватары и VS */}
            <div className="flex items-center justify-center gap-1">
              {/* Вы */}
              <div
                className="flex items-center justify-center h-32 w-32 rounded-full"
                style={{
                  border: "2px solid #623FA5",
                  boxShadow: "0px 4px 0px 0px #623FA5",
                  backgroundColor: "#B3B7FF",
                }}
              >
                <img
                  src={dataUnsafe?.user?.photo_url || avatarImg}
                  className="h-32 w-32 rounded-full"
                />
              </div>

              {/* VS */}
              {opponent && (
                <img
                  src={vs}
                  alt="VS"
                  className="w-12 h-16"
                />)}

              {/* Противник */}
              {opponent && (
                <div
                  className="flex items-center justify-center h-32 w-32 rounded-full"
                  style={{
                    border: "2px solid #623FA5",
                    boxShadow: "0px 4px 0px 0px #623FA5",
                    backgroundColor: opponent ? "#FF7F81" : "#7D41D6",
                  }}
                >
                  <img
                    src={
                      opponent
                        ? opponentImg
                        : nullOpponentImg
                    }
                    className="h-32 w-32 rounded-full"
                  />
                </div>
              )}
            </div>

            {/* Нижний ряд: статистика */}
            <div className="flex items-center justify-center gap-1">
              {/* Ваша статистика */}
              <div className="text-center flex-1 flex-col min-w-32">
                <p className="font-bold text-xl text-[#F6F3FC]">Вы</p>
                <div className="flex justify-center">
                  <Icon
                    icon={icons.StatsFill}
                    activeIcon={icons.Ticket}
                    className="w-5 h-5 fill-[#F6F3FC] mr-1 opacity-80"
                  />
                  <p className="text-sm text-[#F6F3FC]">{myScore}</p>
                </div>
              </div>

              {/* Пустое место для выравнивания */}
              {opponent && <div className="flex-1 w-12"></div>}

              {/* Статистика противника */}
              {opponent && (
                <div className="text-center flex-1 flex-col min-w-32">
                  <p className="font-bold text-xl text-[#F6F3FC]">
                    {opponent ? opponent.name : "Ожидаем"}
                  </p>
                  <div className="flex justify-center">
                    {opponent && <Icon
                      icon={icons.StatsFill}
                      activeIcon={icons.Ticket}
                      className="w-5 h-5 fill-[#F6F3FC] mr-1 opacity-80"
                    />}
                    <p className="text-sm text-[#F6F3FC]">
                      {opponent ? opponent.score ? opponent.score : "-" : "Пригласи друга"}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Кнопки */}
      <div className="mt-5 flex flex-col gap-3 w-full">
        {tickets > 0 ? (
          <ButtonLink
            icon={icons.Ticket}
            to="/game"
            onClick={() => gameStore.startNewGame()}
            text="Играть"
            afterText="x1"
            className="bg-[#C9F456] text-[#247A0A] hover:bg-gray-100 py-2 mx-3.5"
            iconClassName="fill-[#247A0A]"
            style={{
              boxShadow: "0px 4px 0px 0px #247A0A",
            }}
          />
        ) : (
          <ButtonLink
            icon={icons.Ticket}
            text="Недостаточно"
            to="/shop"
            className="bg-[#F335B4] text-[#F6F3FC] py-2 mx-3.5"
            iconClassName="fill-[#F6F3FC]"
            style={{
              boxShadow: "0px 4px 0px 0px #AF0C79",
            }}
            disabled
          />
        )}
        <Button
          icon={icons.Tg}
          text="Играть с другом"
          className="bg-[#F6F3FC] text-[#6641A9] py-2 mx-12 hidden"
          iconClassName="fill-[#6641A9]"
          style={{
            boxShadow: "0px 4px 0px 0px #623FA5",
          }}
          onClick={async () => {
            await gameStore.inviteFriends(true);
          }}
        />
      </div>

      {/* Модальное окно с ссылкой приглашения */}
      <InviteLinkModal
        isOpen={showInviteModal}
        onClose={handleCloseInviteModal}
        inviteLink={inviteLink}
      />
    </div>
  );
});

export default GameActions;
