import React from "react";
import Confetti from 'react-confetti';
import TicketCounter from "./TicketCounter";

const Modal = ({ isOpen, onClose, openConfetti = false, hasCloseButton = true, title, children, footer, useFullHeight = false, backgroundImage }) => {
  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 z-50 flex justify-center items-end"
      onClick={onClose}
      style={{
        backgroundColor: "#0D0D0DD9"
      }}
    >
      {/* Фоновое изображение */}
      {backgroundImage && (
        <div className="absolute inset-x-0 top-0 bottom-[50%] flex justify-center items-center pointer-events-none overflow-hidden">
          <img
            src={backgroundImage}
            alt=""
            className="max-h-full w-auto max-w-[80%] object-contain scale-up-animation"
            style={{
              height: 'min(100%, 35vh)',
              transformOrigin: 'center center'
            }}
          />
        </div>
      )}
      {openConfetti && <Confetti
        width={window.innerWidth}
        height={window.innerHeight}
        recycle={false}
        numberOfPieces={2000}
        colors={['#5B2E98', '#F6F3FC', '#6641A9', '#A67CC8', '#D9B3E6', '#C9F456']}
        gravity={0.1}
        tweenDuration={15000}
      />}
      <div
        className={`bg-[#5B2E98] w-full max-w-md rounded-t-2xl text-center relative flex pb-4 flex-col ${useFullHeight ? 'h-[92vh]' : ''}`}
        style={{
          background: "linear-gradient(180deg, #9141FD 45%, #526FF9 100%)",
          border: "3px solid #623FA5",
          borderTopLeftRadius: "45px",
          borderTopRightRadius: "45px",
          marginTop: backgroundImage ? '50%' : '0'
        }}
        onClick={(e) => e.stopPropagation()} // Остановка всплытия события
      >
        {/* Хедер */}
        <div
          className="pt-4 mb-4 flex-shrink-0"
          style={{
            background:
              "linear-gradient(90deg, rgba(102, 65, 169, 0) 0%, rgba(102, 65, 169, 0.49) 30%, rgba(102, 65, 169, 0.49) 70%, rgba(102, 65, 169, 0) 100%)",
          }}
        >
          {/* Заголовок */}
          <div className="flex items-center gap-2 justify-center">
            <h2 className="text-[#F6F3FC] text-2xl font-bold uppercase">{title}</h2>
          </div>

          <div
            className="w-full mt-4"
            style={{
              height: "1.5px",
              background:
                "linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.49) 30%, rgba(255, 255, 255, 0.49) 70%, rgba(255, 255, 255, 0) 100%)",
            }}
          ></div>
        </div>

        {/* Содержимое */}
        <div className="flex-1 overflow-y-auto">
          {children}
        </div>

        {/* Футер */}
        {footer && (
          <div className="flex-shrink-0">
            {footer}
          </div>
        )}

        {/* Кнопка закрытия */}
        {hasCloseButton && <button
          className="absolute top-3 right-4 flex items-center justify-center"
          onClick={onClose}
        >
          <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ filter: 'drop-shadow(0px 4px 0px #623FA5)' }}>
            <path d="M17 7L7 17M7 7L17 17" stroke="#F6F3FC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </button>}
      </div>
      {isOpen && <TicketCounter />}
    </div>
  );
};

export default Modal;
