// src/services/postbackService.js

const BASE_URL = 'https://tracking.onclicka.com/in/postbacks';

class PostbackService {
    static async sendPostback(params) {
        const { click_id, token, campaign_id } = params;

        // Проверяем все параметры по отдельности для лучшей диагностики
        if (!click_id) {
            console.log('[Postback] Missing click_id');
            return;
        }
        if (!token) {
            console.log('[Postback] Missing token');
            return;
        }
        if (!campaign_id) {
            console.log('[Postback] Missing campaign_id');
            return;
        }

        console.log('[Postback] All required params present:', { click_id, token, campaign_id });

        try {
            const url = new URL(BASE_URL);
            url.searchParams.set('token', token);
            url.searchParams.set('campaign_id', campaign_id);
            url.searchParams.set('click_id', click_id);
            url.searchParams.set('payout', '0');

            const fullUrl = url.toString();
            console.log('[Postback] Built URL:', fullUrl);

            try {
                console.log('[Postback] Starting request...');
                const response = await fetch(fullUrl, {
                    method: 'GET',
                    mode: 'no-cors'
                });
                console.log('[Postback] Request completed (no-cors mode, cannot see actual response)');
            } catch (error) {
                console.error('[Postback] Network error:', error.message);
                console.error('[Postback] Full error:', error);
            }
        } catch (error) {
            console.error('[Postback] URL construction error:', error.message);
            console.error('[Postback] Full error:', error);
        }
    }
}

export default PostbackService;
