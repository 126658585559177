import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const Go = () => {
    const location = useLocation();
    const [debug, setDebug] = useState({
        params: {},
        startapp: null,
        combined: null,
        telegramLink: null,
        error: null
    });

    useEffect(() => {
        console.log('Go component mounted');
        console.log('Current location:', location);
        
        try {
            // Получаем все параметры из URL
            const params = new URLSearchParams(location.search);
            console.log('URL params:', Object.fromEntries(params));
            
            // Собираем все параметры трекинга
            const trackingParams = {};
            const trackingPrefixes = ['utm_', 'campaign_', 'click_', 'token']; // Префиксы параметров для сбора

            for (const [key, value] of params.entries()) {
                // Проверяем, начинается ли параметр с одного из префиксов
                if (trackingPrefixes.some(prefix => key.startsWith(prefix))) {
                    trackingParams[key] = decodeURIComponent(value);
                }
            }
            console.log('Collected tracking params:', trackingParams);

            // Получаем startapp параметр
            const startapp = params.get('startapp');
            console.log('Raw startapp:', startapp);
            
            let decodedStartapp = null;
            
            if (startapp) {
                try {
                    // Заменяем URL-safe символы обратно для base64
                    const base64Fixed = startapp
                        .replace(/-/g, '+')
                        .replace(/_/g, '/');
                    console.log('Fixed base64:', base64Fixed);
                    
                    const decodedString = atob(base64Fixed);
                    console.log('Decoded string:', decodedString);
                    
                    decodedStartapp = JSON.parse(decodedString);
                    console.log('Parsed startapp:', decodedStartapp);
                } catch (e) {
                    console.error('Ошибка при декодировании startapp:', e);
                    setDebug(prev => ({ ...prev, error: e.message }));
                }
            }

            // Создаем общий объект, перенося поля из startapp в корень
            const combinedData = {
                ...trackingParams, // Все параметры трекинга
                ...(decodedStartapp || {}), // Все поля из startapp
            };

            // Кодируем общий объект в base64
            const jsonString = JSON.stringify(combinedData);
            console.log('JSON to encode:', jsonString);
            
            const encodedData = btoa(jsonString)
                .replace(/\+/g, '-')
                .replace(/\//g, '_')
                .replace(/=+$/, '');

            // Формируем финальную ссылку
            const telegramLink = `https://t.me/js_solitaire_bot/game?startapp=${encodedData}`;

            setDebug({
                params: trackingParams,
                startapp: decodedStartapp,
                combined: combinedData,
                telegramLink,
                error: null
            });

            // Раскомментируйте следующую строку для включения автоматического редиректа
            window.location.href = telegramLink;

        } catch (error) {
            console.error('Ошибка при обработке параметров:', error);
            setDebug(prev => ({ ...prev, error: error.message }));
        }
    }, [location]);

    return (
        <div></div>
        // <div className="flex items-center justify-center min-h-screen">
        //     <div className="text-center p-4">
        //         <h1 className="text-xl mb-4">Отладочная информация</h1>
                
        //         {debug.error ? (
        //             <div className="text-red-500 mb-4">
        //                 Ошибка: {debug.error}
        //             </div>
        //         ) : null}
                
        //         <div className="text-left mb-4">
        //             <h2 className="font-bold">Параметры:</h2>
        //             <pre className="bg-gray-100 p-2 rounded">
        //                 {JSON.stringify(debug.params, null, 2)}
        //             </pre>
        //         </div>

        //         <div className="text-left mb-4">
        //             <h2 className="font-bold">Startapp данные:</h2>
        //             <pre className="bg-gray-100 p-2 rounded">
        //                 {JSON.stringify(debug.startapp, null, 2)}
        //             </pre>
        //         </div>

        //         <div className="text-left mb-4">
        //             <h2 className="font-bold">Объединенные данные:</h2>
        //             <pre className="bg-gray-100 p-2 rounded">
        //                 {JSON.stringify(debug.combined, null, 2)}
        //             </pre>
        //         </div>

        //         {debug.telegramLink && (
        //             <div className="text-left mb-4">
        //                 <h2 className="font-bold">Telegram ссылка:</h2>
        //                 <div className="break-all bg-gray-100 p-2 rounded">
        //                     {debug.telegramLink}
        //                 </div>
        //             </div>
        //         )}
        //     </div>
        // </div>
    );
};

export default Go;
