import React, { useEffect, useRef } from "react";
import BottomNavigation from "../components/BottomNavigation";
import TicketCounter from "../components/TicketCounter";
import { observer } from "mobx-react-lite";
import gameStore from "../store/store";
import bannerImg from "../assets/img/invite.png";
import TicketReward from "../components//TicketReward";
import icons from "../assets/icons";
import ButtonLink from "../components/ButtonLink";
import Button from "../components/Button";
import Icon from "../components/Icon";
import InviteLinkModal from "../components/InviteLinkModal";
import opponentImg from "../assets/img/opponent.png";

const FriendsPage = observer(() => {
  const { friends, showInviteModal, inviteLink } = gameStore;

  useEffect(() => {
    gameStore.fetchFriends();
  }, []);

  const handleCloseInviteModal = () => {
    gameStore.showInviteModal = false;
  };

  return (
    <div
      className="h-screen flex flex-col items-center justify-between overflow-y-auto"
      style={{ paddingTop: "72px", paddingBottom: "85px" }}
    >
      {/* Заголовок */}
      <TicketCounter />

      {/* Контент */}
      <div className="w-full flex flex-col items-center flex-1">
        {/* Блок с баннером */}
        <div
          className="relative w-11/12 max-w-md rounded-xl"
          style={{
            // background: "linear-gradient(180deg, #9141FD 0%, #526FF9 100%)",
            border: "2px solid #623FA5"
          }}
        >
          <img
            src={bannerImg}
            alt="Приглашайте друзей"
            className="w-full h-full object-cover"
          />
          <p
            className="absolute bottom-4 text-[#F6F3FC] text-lg font-bold text-center text-xl"
          >
            ПРИГЛАШАЙТЕ ДРУЗЕЙ, ЗАРАБАТЫВАЙТЕ БОНУСЫ!
          </p>
        </div>

        {/* Информация о приглашении */}
        <div className="mt-2 w-11/12 max-w-md">
          <div className="grid grid-cols-2 gap-1">
            <div
              className="py-2 px-0 rounded-xl text-center"
              style={{
                background: "linear-gradient(0deg, #623FA5, #623FA5), radial-gradient(66.93% 66.93% at 50.09% 12.6%, rgba(247, 163, 255, 0.2) 0%, rgba(247, 163, 255, 0) 100%)",
                backgroundBlendMode: "overlay",
                border: "2px solid #623FA5",
              }}
            >
              <TicketReward text="+10" icon={icons.Ticket} />
              <p className="text-xs font-bold" style={{ color: "#F6F3FC99" }}>вы получите за приглашенного друга</p>
            </div>
            <div
              className="py-2 px-0 rounded-xl text-center"
              style={{
                background: "linear-gradient(0deg, #623FA5, #623FA5), radial-gradient(66.93% 66.93% at 50.09% 12.6%, rgba(247, 163, 255, 0.2) 0%, rgba(247, 163, 255, 0) 100%)",
                backgroundBlendMode: "overlay",
                border: "2px solid #623FA5",
              }}
            >
              <TicketReward text="+10" icon={icons.Ticket} />
              <p className="text-xs font-bold" style={{ color: "#F6F3FC99" }}>ваш друг получает в подарок</p>
            </div>
          </div>
        </div>

        {/* Список друзей */}
        <div className="mt-3 w-11/12 max-w-md flex-1 flex flex-col">
          <span
            className="text-[#F6F3FC] font-bold text-xl text-center mb-2 uppercase"
            style={{ textShadow: "0px 4px 0px #623FA5" }}
          >
            Топ-100 друзей в игре
          </span>
          <div
            className="rounded-xl p-2"
            style={{
              border: "2px solid #623FA5",
              background: "#623FA5A8",
            }}
          >
            {friends.length > 0 ? (
              <ul
                className="flex-1 overflow-y-auto"
                style={{
                  maxHeight: "175px",
                }}
              >
                {friends.map((friend, index) => (
                  <li
                    key={index}
                    className={`flex items-center justify-between py-1.5 mr-5 pl-1 ${
                      index !== friends.length - 1 ? "border-b border-[#F6F3FC33]" : ""
                    }`}
                  >
                    {/* Аватарка и имя */}
                    <div className="flex items-center gap-2">
                      <div
                        className="w-8 h-8 rounded-full flex items-center justify-center border"
                        style={{
                          backgroundColor: "#D9B3FF",
                          border: "1px solid #F6F3FC",
                        }}
                      >
                        <img
                          src={`${friend.avatar || opponentImg}`}
                          alt={`${friend.name}'s avatar`}
                          className="w-8 h-8 rounded-full"
                        />
                      </div>
                      <div className="player-name-container">
                        <span 
                          className={`player-name text-[#F6F3FC] font-bold uppercase`}
                          ref={(el) => {
                            if (el) {
                              const container = el.parentElement;
                              if (el.offsetWidth > container.offsetWidth) {
                                el.classList.add('animate-name');
                              }
                            }
                          }}
                        >
                          {friend.name}
                        </span>
                      </div>
                    </div>

                    {/* Очки */}
                    <div className="flex">
                      <Icon
                        icon={icons.StatsFill}
                        activeIcon={icons.Ticket}
                        className="w-5 h-5 fill-[#F6F3FC] mr-1 opacity-80"
                      />
                      <span className="text-[#F6F3FC] font-bold">{friend.score}</span>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <div
                className="text-center text-xl uppercase font-bold flex-1 flex items-center justify-center"
                style={{
                  minHeight: "175px",
                  color: "#F6F3FC80"
                }}
              >
                Пока что вы<br />никого не<br />пригласили
              </div>
            )}
          </div>
        </div>

        {/* Кнопка приглашения */}
        <div className="w-11/12 max-w-md flex mt-3">
          <div className="flex-1 pb-1">
            <Button
              text="Пригласить друзей"
              className="bg-[#C9F456] text-[#247A0A] hover:bg-gray-100 w-full h-[50px]"
              style={{
                boxShadow: "0px 4px 0px 0px #247A0A",
              }}
              onClick={async () => {
                await gameStore.inviteFriends();
              }}
            />
          </div>
          <div className="ml-2">
            <Button
              icon={icons.Copy}
              className="bg-[#C9F456] text-[#247A0A] hover:bg-gray-100 h-[50px] w-[50px]"
              iconClassName="flex ml-0"
              style={{
                boxShadow: "0px 4px 0px 0px #247A0A",
              }}
              onClick={async () => {
                await gameStore.inviteFriends();
              }}
            />
          </div>
        </div>
      </div>

      {/* Модальное окно с ссылкой приглашения */}
      <InviteLinkModal
        isOpen={showInviteModal}
        onClose={handleCloseInviteModal}
        inviteLink={inviteLink}
      />

      {/* Навигация */}
      <BottomNavigation />
      <style jsx>{`
        .player-name-container {
          max-width: 120px;
          overflow: hidden;
          position: relative;
        }

        .player-name {
          display: inline-block;
          white-space: nowrap;
          position: relative;
        }

        .animate-name {
          animation: marquee 8s linear infinite;
        }

        @keyframes marquee {
          0%, 15% {
            transform: translateX(0);
          }
          85%, 100% {
            transform: translateX(calc(-100% + 120px));
          }
        }

        @media (min-width: 768px) {
          .player-name-container {
            max-width: 150px;
          }
          
          @keyframes marquee {
            0%, 15% {
              transform: translateX(0);
            }
            85%, 100% {
              transform: translateX(calc(-100% + 150px));
            }
          }
        }
      `}</style>
    </div>
  );
});

export default FriendsPage;
