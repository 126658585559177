// src/utils/loadGtag.js

export const loadGtag = (measurementId) => {
  return new Promise((resolve, reject) => {
    if (window.gtag) {
      // Если gtag уже загружен
      resolve();
      return;
    }

    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag(){ window.dataLayer.push(arguments); }
      window.gtag = gtag; // Делает gtag глобально доступным

      gtag('js', new Date());
      gtag('config', measurementId, {
        allow_google_signals: false,
        allow_ad_personalization_signals: false,
        cookie_flags: 'max-age=7200;secure;samesite=none',
      });

      resolve();
    };
    script.onerror = () => reject(new Error('Не удалось загрузить gtag.js'));
    document.head.appendChild(script);
  });
};
