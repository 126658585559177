import axios from "axios";
import { API_BASE_URL, API_ENDPOINTS } from '../config';
import icons from "../assets/icons";
import Icon from '../components/Icon';
import gameStore from "../store/store";

class UpdateService {
  constructor(store) {
    this.store = store;
    this.notificationQueue = [];
    this.isShowingNotification = false;
    this.startPolling();
  }

  startPolling() {
    setInterval(() => {
      if (this.store.token) {
        this.checkUpdates();
      }
    }, 4000);
  }

  async checkUpdates() {
    try {
      const response = await axios.get(`${API_BASE_URL}${API_ENDPOINTS.GET_UPDATES}`, {
        headers: {
          'Authorization': `Bearer ${this.store.token}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.data.notifications && Array.isArray(response.data.notifications)) {
        for (const notification of response.data.notifications) {
          await this.handleNotification(notification);
          
          if (notification.read_callback) {
            try {
              await axios.get(notification.read_callback, {
                headers: {
                  'Authorization': `Bearer ${this.store.token}`,
                  'Content-Type': 'application/json'
                }
              });
            } catch (error) {
              console.error('Ошибка при вызове callback для нотификации:', error);
            }
          }
        }
      }
    } catch (error) {
      console.error('Ошибка при проверке обновлений:', error);
      
      // Проверяем, является ли ошибка 401 (Unauthorized)
      if (error.response && error.response.status === 401) {
        const content = (
          <>
            <span className="text-lg font-bold">
              Обнаружена другая активная сессия игры. 
              Пожалуйста, закройте все другие окна с игрой.
            </span>
          </>
        );
        
        // Добавляем уведомление в очередь
        this.addToQueue(content, () => {
          // После показа уведомления обновляем статус авторизации
          this.store.updateStatus(false);
        });
      }
    }
  }

  showNextNotification = async () => {
    if (this.isShowingNotification || this.notificationQueue.length === 0) {
      return;
    }

    this.isShowingNotification = true;
    const notification = this.notificationQueue.shift();
    this.store.showAlert(notification.content);

    // Выполняем sideEffect если он есть
    if (notification.sideEffect) {
      notification.sideEffect();
    }

    // Ждем небольшую паузу перед следующим уведомлением (3 сек показ + 300мс пауза)
    await new Promise(resolve => setTimeout(resolve, 3300));
    
    this.isShowingNotification = false;
    this.showNextNotification(); // Показываем следующее, если есть
  }

  addToQueue(content, sideEffect = null) {
    this.notificationQueue.push({ content, sideEffect });
    if (!this.isShowingNotification) {
      this.showNextNotification();
    }
  }

  handleNotification = async (notification) => {
    const { type, data } = notification;

    switch (type) {
      case 'profile':
        await this.store.fetchProfile();
        break;
      case 'new-profile':
        await this.store.handleNewProfile();
        break;
      case 'leaderboard':
        await this.store.fetchLeaderboard();
        break;
      case 'friends':
        await this.store.fetchFriends();
        break;
      case 'tickets-income':
        if (data && typeof data.total === 'number' && typeof data.income === 'number') {
          const content = (
            <>
              <span className="text-lg font-bold">+{data.income}</span>
              <Icon
                icon={icons.Ticket}
                className="w-5 h-5 fill-[#F6F3FC]"
              />
            </>
          );
          this.addToQueue(content, () => this.store.updateTickets(data.total, data.income));
        }
        break;
      case 'transaction:complete':
        if (data && typeof data.tickets === 'number') {
          const content = (
            <>
              <span className="text-lg font-bold">Спасибо за покупку +{data.tickets}</span>
              <Icon
                icon={icons.Ticket}
                className="w-5 h-5 fill-[#F6F3FC]"
              />
            </>
          );
          this.addToQueue(content);
          const analyticsService = gameStore.analyticsService;
          if(analyticsService) {
            analyticsService.sucssessTransaction(data.goods_id, data.tickets, data.price);
          }
        }
        break;
      case 'friend:invite-accepted':
        const content = (
          <>
            <span className="text-lg font-bold">🤝 {data.friend_username}</span>
          </>
        );
        this.addToQueue(content);
        break;
    }
  }
}

export default UpdateService;
