// src/App.js
import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import MainApp from "./features/MainApp";
import { AuthProvider } from "./components/auth/AuthContext";
import Alert from "./components/Alert";
import Loader from "./components/Loader";
import AnalyticsService from './services/analyticsService';
import PostbackService from './services/postbackService';
import gameStore from './store/store';
import { loadGtag } from './utils/loadGtag';
import { programmaticReload } from './utils/reload';

const AppContent = () => {
  return (
    <>
      <Alert />
      <MainApp />
    </>
  );
};

const App = () => {
  const [isUtmProcessing, setIsUtmProcessing] = useState(true);
  const [analyticsInitialized, setAnalyticsInitialized] = useState(false);

  useEffect(() => {

    if (window.Telegram && window.Telegram.WebApp) {
      const tg = window.Telegram.WebApp;
      tg.ready();
      tg.expand();
    }

    const initializeAnalytics = async () => {
      try {
        let startParam = window.Telegram?.WebApp?.initDataUnsafe?.start_param;
        if (startParam) {
          const decoded = atob(startParam);
          const params = JSON.parse(decoded);

          console.log('[App] Decoded start params:', params);

          const hasUtm = params.utm_source || params.utm_medium || params.utm_campaign || params.campaign_id || params.click_id;

          if (hasUtm && !window.location.search.includes('utm_source')) {
            const utm = {
              utm_id: params.utm_id || '',
              utm_source: params.utm_source || '',
              utm_medium: params.utm_medium || '',
              utm_campaign: params.utm_campaign || '',
              utm_content: params.utm_content || '',
              campaign_id: params.campaign_id || '',
              click_id: params.click_id || '',
              token: params.token || ''
            };

            console.log('[App] Reloading with UTM params:', utm);
            const queryString = new URLSearchParams(utm).toString();
            programmaticReload(`/?${queryString}`);
            return;
          }
        }
      } catch (error) {
        console.error('Ошибка при обработке UTM-тегов:', error);
      } finally {
        setIsUtmProcessing(false);
      }
    };

    initializeAnalytics();
  }, []);

  useEffect(() => {
    if (isUtmProcessing) return;

    const initAnalytics = async () => {
      try {
        await loadGtag('G-M03RR9Z0NZ');
        console.log('gtag.js загружен и инициализирован.');
      } catch (error) {
        console.error('Не удалось загрузить gtag.js:', error);
      }

      const analyticsService = new AnalyticsService();
      await analyticsService.initializeAnalytics();
      gameStore.setAnalyticsService(analyticsService);

      const isProgrammaticReload = localStorage.getItem('isProgrammaticReload') === 'true';
      const navigationTypeInitial = window.performance
        .getEntriesByType('navigation')
        .map((nav) => nav.type)[0];

      // Проверяем наличие параметров в URL
      const params = new URLSearchParams(window.location.search);
      const click_id = params.get('click_id');
      const token = params.get('token');
      const campaign_id = params.get('campaign_id');
      
      console.log('[App] URL params:', {
        click_id,
        token,
        campaign_id,
        search: window.location.search
      });

      // Сохраняем все параметры если они есть
      if (click_id && token && campaign_id) {
        const trackingParams = {
          click_id,
          token,
          campaign_id
        };
        console.log('[App] Saving tracking params to localStorage:', trackingParams);
        localStorage.setItem('tracking_params', JSON.stringify(trackingParams));
      } else {
        console.log('[App] Missing some tracking params, not saving');
      }

      if (isProgrammaticReload) {
        // Программная перезагрузка: отправляем `session_start`
        await analyticsService.startSession();
        // Отправляем постбэк если есть все параметры
        const trackingParams = localStorage.getItem('tracking_params');
        console.log('[App] Programmatic reload, tracking params from storage:', trackingParams);
        if (trackingParams) {
          const params = JSON.parse(trackingParams);
          console.log('[App] Sending postback with params:', params);
          await PostbackService.sendPostback(params);
          localStorage.removeItem('tracking_params');
        }
        localStorage.removeItem('isProgrammaticReload');
      } else if (navigationTypeInitial === 'reload') {
        // Пользовательская перезагрузка: отправляем `game_reload`
        await analyticsService.logReload();
      } else {
        // Новая сессия
        await analyticsService.startSession();
        // Отправляем постбэк если есть все параметры
        const trackingParams = localStorage.getItem('tracking_params');
        console.log('[App] New session, tracking params from storage:', trackingParams);
        if (trackingParams) {
          const params = JSON.parse(trackingParams);
          console.log('[App] Sending postback with params:', params);
          await PostbackService.sendPostback(params);
          localStorage.removeItem('tracking_params');
        }
      }

      setAnalyticsInitialized(true);
    };

    initAnalytics();
  }, [isUtmProcessing]);

  const showLoader = isUtmProcessing || !analyticsInitialized;

  return showLoader ? (
    <Loader />
  ) : (
    <BrowserRouter>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;
